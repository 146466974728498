import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, reactive, ref } from "vue";

interface Props {
  data: any[];
  lineData?: any[];
  yTitle?: string;
  colors?: string;
  seriesName?: string;
  lineSeriesName?: string;

  data_2?: any[];
  colors_2?: string;
  seriesName_2?: string;

  data_3?: any[];
  colors_3?: string;
  seriesName_3?: string;

  data_4?: any[];
  colors_4?: string;
  seriesName_4?: string;

  legendStyle?: any;
  legendLayout?: string;
  xTicks?: string;
  yTicks?: string;

  withExtraYScale?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AreaChart',
  props: {
    data: {},
    lineData: {},
    yTitle: {},
    colors: {},
    seriesName: {},
    lineSeriesName: {},
    data_2: {},
    colors_2: {},
    seriesName_2: {},
    data_3: {},
    colors_3: {},
    seriesName_3: {},
    data_4: {},
    colors_4: {},
    seriesName_4: {},
    legendStyle: {},
    legendLayout: {},
    xTicks: {},
    yTicks: {},
    withExtraYScale: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const {
  data,
  colors,
  data_2,
  colors_2,
  data_3,
  colors_3,
  data_4,
  colors_4,
  lineData,
  yTitle,
  seriesName,
  seriesName_2,
  seriesName_3,
  seriesName_4,
  lineSeriesName,
  legendStyle,
  legendLayout,
  withExtraYScale,
  xTicks,
  yTicks,
} = reactive(props);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const anychart = require("anychart/dist/js/anychart-cartesian.min.js");

const el = ref(null);
let chart = reactive<any>(null);

onMounted(() => {
  // create a Doughnut pie chart and set the data
  chart = anychart.line();
  chart.background(null);

  chart.barsPadding(0);

  chart.barGroupsPadding(2);

  const series = chart.line(data);
  series.name(seriesName);
  series.seriesType("spline-area");

  xTicks && chart.xScale().ticks().interval(xTicks);
  yTicks && chart.yScale().ticks().interval(yTicks);

  if (colors) {
    series.normal().fill(colors, 0.3);
    series.selected().fill(colors, 0.5);
    series.normal().stroke(colors, 2, "solid");
    series.hovered().stroke(colors, 2, "solid");
    series.selected().stroke(colors, 2, "solid");
  }

  if (data_2) {
    const series2 = chart.line(data_2);
    seriesName_2 && series2.name(seriesName_2);
    series2.seriesType("spline-area");

    if (colors_2) {
      series2.normal().fill(colors_2, 0.3);
      series2.selected().fill(colors_2, 0.5);
      series2.normal().stroke(colors_2, 2, "solid");
      series2.hovered().stroke(colors_2, 2, "solid");
      series2.selected().stroke(colors_2, 2, "solid");
    }
  }

  if (data_3) {
    const series3 = chart.line(data_3);
    seriesName_3 && series3.name(seriesName_3);
    series3.seriesType("spline-area");

    if (colors_3) {
      series3.normal().fill(colors_3, 0.3);
      series3.selected().fill(colors_3, 0.5);
      series3.normal().stroke(colors_3, 2, "solid");
      series3.hovered().stroke(colors_3, 2, "solid");
      series3.selected().stroke(colors_3, 2, "solid");
    }
  }

  if (data_4) {
    const series4 = chart.line(data_4);
    seriesName_4 && series4.name(seriesName_4);
    series4.seriesType("spline-area");

    if (colors_4) {
      series4.normal().fill(colors_4, 0.3);
      series4.selected().fill(colors_4, 0.5);
      series4.normal().stroke(colors_4, 2, "solid");
      series4.hovered().stroke(colors_4, 2, "solid");
      series4.selected().stroke(colors_4, 2, "solid");
    }
  }

  yTitle && chart.yAxis().title(yTitle);

  // adding extra Y scale
  if (withExtraYScale) {
    const extraYScale = anychart.scales.linear();
    extraYScale.minimum(0);
    extraYScale.ticks().interval(20);

    // adding and adjust extra Y axis
    const extraYAxis = chart.yAxis(1);
    extraYAxis.orientation("right");
    extraYAxis.labels().format("{%value}%");
    extraYAxis.scale(extraYScale);
    extraYAxis.ticks().enabled(false);

    if (lineData) {
      const lineSeries = chart.line(lineData);
      lineSeries.name(lineSeriesName);
      lineSeries.seriesType("spline").yScale(extraYScale);

      // configure the visual settings of the second series
      lineSeries.normal().stroke("#2d7189", 2);
      lineSeries.hovered().stroke("#2d7189", 2);
      lineSeries.selected().stroke("#2d7189", 2);
    }
  }

  chart.xGrid().enabled(true);
  chart.xGrid().stroke({
    color: "#fff",
    thickness: 2,
    opacity: 0.1,
  });
  chart.yGrid().enabled(true);
  chart.yGrid().stroke({
    color: "#fff",
    thickness: 2,
    opacity: 0.1,
  });

  legendStyle && chart.legend(legendStyle);
  legendLayout && chart.legend().itemsLayout(legendLayout);

  chart.animation(true);

  chart.xAxis().ticks().enabled(false);
  chart.yAxis().ticks().enabled(false);

  chart.container(el.value).draw();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "w-full mx-auto",
    ref_key: "el",
    ref: el
  }, null, 512))
}
}

})